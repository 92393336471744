import axios from "@/plugins/axios";

const login = (data) => {
  return axios.post("/agency/api/v1/sign-in/email", data);
};

const loginId = (data) => {
  return axios.post("/agency/api/v1/sign-in/id", data);
};

const logout = () => {
  return axios.post("/agency/api/v1/sign-out");
};

const otp = (data) => {
  return axios.post("/agency/api/v1/sign-in/otp", data);
};

const otpRecovery = (data) => {
  return axios.post("/agency/api/v1/sign-in/otp-recovery", data);
};

const sendNewPassword = (data) => {
  return axios.post("/agency/api/v1/request-password-reset", data);
};

const updatePassword = (data) => {
  return axios.post("/agency/api/v1/password-reset", data);
};

const confirmEmail = (data) => {
  return axios.post("/agency/api/v1/confirm-email", data);
};

const signUp = (data) => {
  return axios.post("/agency/api/v1/sign-up/nafath", data);
};

const getSignUpInvitation = (t) => {
  return axios.get(`/agency/api/v1/sign-up/invitation?invitation_token=${t}`);
};

const signUpInvitation = (data) => {
  return axios.post("/agency/api/v1/sign-up/invitation", data);
};

const getSignUpNafath = (id) => {
  return axios.get(`/agency/api/v1/sign-up/nafath/${id}`);
};

const signUpNafathReSend = (id) => {
  return axios.post(`/agency/api/v1/sign-up/nafath/${id}/re-send`);
};

const signUpNafathAuth = (id) => {
  return axios.post(`/agency/api/v1/sign-up/nafath/${id}/auth`);
};

export default {
  login,
  logout,
  otp,
  otpRecovery,
  sendNewPassword,
  updatePassword,
  confirmEmail,
  signUp,
  getSignUpInvitation,
  signUpInvitation,
  getSignUpNafath,
  signUpNafathReSend,
  signUpNafathAuth,
  loginId,
};
